<template>
  <Head>
    <title>Займы Онлайн - от новых МФО на банковскую карту. Круглосуточно.</title>
    <link rel="icon" href="/favicon.ico">
    <link rel="icon" href="/favicon.png">
    <meta name="description" content="Мы предоставили вам cписок на всех лучших МФО по выдаче круглосуточных займов на любые цели. Посмотрите все наши условия по круглосуточным микрозаймам в которых можно без отказа и проверок получить деньги на карту" />
  </Head>
  <div class="home-page">
    <v-modal v-if="showModal === true" @closeModal="closeModal"/>
    <section class="start">
      <div class="content">
        <h1 class="title">✅ Новые и малоизвестные займы с онлайн выдачей на карту 24/7 в МФО 2024.</h1>
        <div class="description">
          <h2 class="text">Одобрим даже с текущими просрочками более 90 дней. Подай заявку в течении 10 минут получи
            первый заём на 21 день под 0%</h2>
          <h2 class="text">✅ Займы без страховок и неожиданных списаний с карты</h2>
        </div>
        <div class="start-container">
          <v-calculator />
          <img class="decor-image1" src="@/assets/images/women-group2-zaimi.webp" alt="">
          <img class="decor-image2" src="@/assets/images/women-group-zaimi.webp" alt="">
        </div>
      </div>
      <img class="start-image" src="@/assets/images/background.webp" alt="">
    </section>

    <section class="intro">
      <div class="content">
        <div class="title-text">
          <span class="text">Всего за 10 минут</span>
          <h3 class="h3">Займ онлайн без проблем</h3>
        </div>
        <img class="intro-image" src="@/assets/images/intro-image.webp" alt="">
        <div class="steps">
          <div class="step_item">
            <img class="image" src="@/assets/images/icons/form.svg" alt="">
            <div class="step-info">
              <span class="name">Заполните анкету</span>
              <p class="text">Удобный интерфейс позволит быстро пройти процедуру заполнения</p>
            </div>
          </div>
          <div class="step_item">
            <img class="image" src="@/assets/images/icons/wait.svg" alt="">
            <div class="step-info">
              <span class="name">Дождитесь одобрения </span>
              <p class="text">Рассмотрение заявки займет не более 5 минут</p>
            </div>
          </div>
          <div class="step_item">
            <img class="image" src="@/assets/images/icons/money.svg" alt="">
            <div class="step-info">
              <span class="name">Получите деньги</span>
              <p class="text">На банковскую карту, наличными или на электронный кошелек </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="garant">
      <div class="content">
        <div class="title-text">
          <span class="text">Гарантированные условия</span>
          <h3 class="h3">Почему обращаются к нам</h3>
        </div>
        <div class="garant-blocks">
          <div class="block">
            <div class="image-cont">
              <img class="image" src="@/assets/images/man1.webp" alt="">
              <img class="image-ext" src="@/assets/images/man1-ext.webp" alt="">
            </div>
            <span class="text">0% первый заём </span>
          </div>
          <div class="block">
            <div class="image-cont">
              <img class="image" src="@/assets/images/man2.webp" alt="">
              <img class="image-ext" src="@/assets/images/man2-ext.webp" alt="">
            </div>
            <span class="text">10 минут и деньги у вас</span>
          </div>
          <div class="block">
            <div class="image-cont">
              <img class="image" src="@/assets/images/man3.webp" alt="">
              <img class="image-ext" src="@/assets/images/man3-ext.webp" alt="">
            </div>
            <span class="text">99% одобрения</span>
          </div>
        </div>
      </div>
    </section>

    <section class="catalog-section">
      <div class="content">
        <div class="title-text">
          <span class="text">Наши</span>
          <h3 class="h3">Лучшие предложения</h3>
        </div>
        <router-link to="/catalog" class="btn" type="submit">Открыть каталог</router-link>
      </div>
    </section>

    <section class="reviews">
      <div class="content">
        <div class="title-text">
          <span class="text">Нас рекомендуют</span>
          <h3 class="h3">Отзывы наших клиентов</h3>
        </div>
        <div class="nav-btns">
          <svg class="prev" width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Arrow 1" d="M26.5 10.5C27.3284 10.5 28 11.1716 28 12C28 12.8284 27.3284 13.5 26.5 13.5V10.5ZM0.439341 13.0607C-0.146446 12.4749 -0.146446 11.5251 0.439341 10.9393L9.98528 1.3934C10.5711 0.807611 11.5208 0.807611 12.1066 1.3934C12.6924 1.97919 12.6924 2.92893 12.1066 3.51472L3.62132 12L12.1066 20.4853C12.6924 21.0711 12.6924 22.0208 12.1066 22.6066C11.5208 23.1924 10.5711 23.1924 9.98528 22.6066L0.439341 13.0607ZM26.5 13.5H1.5V10.5H26.5V13.5Z" fill="white"/>
          </svg>
          <svg class="next" width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Arrow 1" d="M26.5 10.5C27.3284 10.5 28 11.1716 28 12C28 12.8284 27.3284 13.5 26.5 13.5V10.5ZM0.439341 13.0607C-0.146446 12.4749 -0.146446 11.5251 0.439341 10.9393L9.98528 1.3934C10.5711 0.807611 11.5208 0.807611 12.1066 1.3934C12.6924 1.97919 12.6924 2.92893 12.1066 3.51472L3.62132 12L12.1066 20.4853C12.6924 21.0711 12.6924 22.0208 12.1066 22.6066C11.5208 23.1924 10.5711 23.1924 9.98528 22.6066L0.439341 13.0607ZM26.5 13.5H1.5V10.5H26.5V13.5Z" fill="white"/>
          </svg>
        </div>
        <swiper id="reviews-swiper"
                class="slider"
                :centered-slides="false"
                :space-between="20"
                :modules="modules"
                :navigation="{nextEl: '.next', prevEl: '.prev'}"
                :loop="true"
                :loop-fill-group-with-blank="true"
                :breakpoints="{
                  320: { spaceBetween: 10, slidesPerView: 1.1 },
                  600: { spaceBetween: 20, slidesPerView: 2.2 },
                  1200: { spaceBetween: 20, slidesPerView: 4 },
                }">
          <swiper-slide v-for="r in otzivi" :key="r" class="slide">
            <div class="user-info">
              <span class="name">{{ r.name }}</span>
              <span class="about">{{ r.about }}</span>
            </div>
            <span class="text">{{ r.text }}</span>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="form" id="form">
      <div class="content">
        <div class="title-text">
          <span class="h3">Оставьте свой отзыв о нас</span>
        </div>
        <div class="container">
          <form @submit.prevent="" class="review-form">
            <input class="input" type="text" v-model="formData.fullName" placeholder="Введите ваше имя">
            <textarea class="input textarea" v-model="formData.otziv" rows="5" placeholder="Ваш отзыв"></textarea>
            <button class="btn" type="submit" @click="clearForm">Оставить отзыв</button>
            <span class="error-message" v-show="formFull">{{ formFull }}</span>
          </form>
          <modal-window
              v-if="showModal2 === true"
              @closeAnsw="closeAnsw"
          >
            <div class="modal-text">
              <p class="text">Ваш отзыв успешно отправлен!</p>
              <p class="text">В скором времени он будет добавлен на сайт.</p>
            </div>
          </modal-window>
        </div>
      </div>
      <img class="form-image" src="@/assets/images/background.webp" alt="">
    </section>

    <section class="conditions">
      <div class="content">
        <div class="line">
          <span class="name">Условия получения:</span>
          <span class="text">Первый заём до 10 000 рублей выдается бесплатно в случае своевременного погашения; В случае
            нарушения сроков, размер неустойки составляет 0,10% от суммы просрочки в день, но не более 10% от суммы
            кредита; В случае длительной задержки выплаты информация будет передана в БКИ.</span>
        </div>
        <div class="line">
          <span class="name">Информация о полной стоимости займа и пример расчета:</span>
          <span class="text">ПСК (полная стоимость кредита) в процентах составляет от 0 до 292% годовых.
            <br>
            Пример:
            <br>
            Заём в размере 10 000 рублей выдан на 61 дней с пролонгацией. В году 365 дней, по договору ставка составляет
            0,8% в день (годовая ставка, соответственно будет равна 292%). Сумма процентов за год составляет 29 200 руб.
            (10 000*292% = 29 200), за 1 день 80 руб. (29 200/365 = 80), за 61 дней соответственно 4 880 руб. (80*61 = 4
            880. Общая сумма платежа составляет 14 880 руб. (10 000 руб. основной долг + 4 880 руб. проценты). Процентная
            ставка по займам составляет от 0 до 292% годовых; По потребительским кредитам и кредитным картам - от 17 до
            31% годовых;
          </span>
        </div>
        <div class="line">
          <span class="name">Информация о полной стоимости займа и пример расчета:</span>
          <span class="text">В случае невозвращения в условленный срок суммы кредита или суммы процентов за пользование
            заёмными средствами кредитор вынуждено начислит штраф за просрочку платежа. Большинство кредиторов идут на
            уступки и дают 3 дополнительных рабочих дня для оплаты. Они предусмотрены на случай, к примеру, если
            банковский перевод занял больше времени, чем обычно. Однако, в случае неполучения от Вас какой-либо реакции в
            течение продолжительного времени, будет начислен штраф за просрочку срока погашения размером в среднем 0,10%
            от первоначальной суммы для займов, 0,03% от суммы задолженности в среднем для потребительских кредитов и
            кредитных карт. При несоблюдении Вами условий по погашению кредитов и займов, данные о Вас могут быть переданы
            в реестр должников, а задолженность - коллекторскому агентству для взыскания долга. О всех приближающихся
            сроках платежа кредитор своевременно информирует Вас по СМС или электронной почте. Рекомендуем Вам вносить
            платеж в день получения данных напоминаний. Погашая задолженность в срок, Вы формируете хорошую кредитную
            историю, что повышает Ваши шансы в дальнейшем получить кредит на более выгодных условиях. Предложение не
            является офертой. Конечные условия уточняйте при прямом общении с кредиторами.</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Head } from "@vueuse/head";
import VCalculator from "@/components/UI/vCalculator.vue";
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from "swiper/modules";
import 'swiper/css'
import VModal from "@/components/vModal.vue";
import otzivi from '@/json/otzivi.json'
import ModalWindow from "@/components/UI/ModalWindow.vue";

export default {
  components: {ModalWindow, VModal, VCalculator, Head, Swiper, SwiperSlide },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  data() {
    return {
      otzivi: otzivi,
      allowSwipeNext: true,
      allowSwipePrev: false,
      showModal: false,
      showModal2: false,
      modalShown: false,
      formFull: "",
      formData: {
        fullName: "",
        otziv: ""
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    closeAnsw() {
      this.showModal2 = false
      const err = document.querySelector(".error-message")
      err.style.color = "red";
      document.documentElement.style.overflowY = 'auto'
    },
    clearForm() {
      this.formFull = {};

      if (this.formData.fullName === "" || this.formData.otziv === "") {
        this.formFull = 'Заполнены не все данные!';
      }

      if (this.formData.fullName && this.formData.otziv) {
        const err = document.querySelector(".error-message")
        err.style.color = "var(--text-color)";
        this.formFull = 'Отзыв отправляется...';
      }

      if (this.formFull === "Отзыв отправляется...") {
        const randomNum = Math.floor(Math.random() * (3 - 1 + 1)) + 1
        setTimeout(() => {
          this.showModal2 = true;
          this.formData.fullName = ""
          this.formData.otziv = ""
          this.formFull = '';
        }, randomNum*1000);
      }
    },
    handleScroll() {
      const targetElement = document.getElementById('form');
      const targetOffset = targetElement.offsetTop;
      const scrollY = window.scrollY;

      if (scrollY >= targetOffset) {
        if (this.modalShown === false) {
          this.showModal = true
          this.modalShown = true
          document.documentElement.style.overflowY = 'hidden'
        }
      } else {
        this.showModal = false
        document.documentElement.style.overflowY = 'auto'
      }
    },
    closeModal() {
      this.showModal = !this.showModal
      document.documentElement.style.overflowY = 'auto'
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/styles/base/adaptive"
.home-page
  .start
    background: var(--start-color)
    padding-top: 163px
    padding-bottom: 0
    margin-bottom: 75px
    position: relative
    overflow: hidden
    .start-container
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 100%
      .calculator
        height: max-content
        margin-bottom: 95px
      .decor-image1
        align-self: end
        width: 30%
      .decor-image2
        display: none
    .start-image
      position: absolute
      object-fit: cover
      top: 0
      left: 0
      opacity: 70%
      width: 100%
      height: 100%
    .content
      position: relative
      z-index: 20
      .title
        font-size: 2.75rem
        font-weight: 600
        line-height: 130%
        margin-bottom: 30px
      .description
        width: 60%
        display: flex
        flex-direction: column
        gap: 20px
        padding: 20px 30px
        margin-bottom: 60px
        border-left: 5px solid var(--accent-color)
        background: #FFF
        .text
          max-width: 725px
          color: var(--text-color)
          font-size: 1.375rem
  .intro
    .content
      display: grid
      grid-template-areas: 'title title' "img steps"
      grid-gap: 40px
      .title-text
        grid-area: title
      .intro-image
        grid-area: img
        align-self: end
      .steps
        grid-area: steps
        display: flex
        flex-direction: column
        gap: 30px
        .step_item
          display: flex
          flex-direction: row
          align-items: center
          padding: 30px
          background: var(--card-color)
          border-radius: 15px
          gap: 60px
          .step-info
            display: flex
            flex-direction: column
            gap: 30px
            .name
              font-size: 1.5rem
              font-weight: 600
            .text
              font-size: 1.25rem
              font-weight: 400
  .garant
    .content
      display: flex
      flex-direction: column
      gap: 40px
      .garant-blocks
        display: flex
        flex-direction: row
        gap: 20px
        justify-content: space-between
        .block
          display: flex
          flex-direction: row
          align-items: center
          gap: 30px
          border-radius: 15px
          border: 2px solid #2C9FFA
          background: #2C9FFA
          .image-cont
            .image
              border-top-left-radius: 15px
              border-bottom-left-radius: 15px
              object-fit: cover
            .image-ext
              display: none
          .text
            flex: 1 0 0
            max-width: 180px
            padding-right: 30px
            color: #FFF
            text-align: center
            font-size: 1.25rem
            font-weight: 600
  .catalog-section
    .content
      display: flex
      flex-direction: column
      gap: 40px
      align-items: center
      .title-text
        .text
          text-align: center
      .btn
        border: 1px solid var(--gray-lines-color)
        padding: 20px 100px
        width: fit-content
        scale: 1
        color: white
        border-radius: 100px
        background: var(--gradient)
        font-weight: 600
        font-size: 1.5rem
        transition: 0.2s ease-in-out
        &:hover
          opacity: 0.8
          scale: 1.04
          transition: 0.3s ease-in-out
  .reviews
    .content
      display: grid
      grid-gap: 30px 0
      grid-template-areas: 'title nav' 'slider slider'
      .title-text
        grid-area: title
      .nav-btns
        grid-area: nav
        display: flex
        flex-direction: row
        gap: 10px
        justify-content: flex-end
        align-items: flex-end
        .prev
          cursor: pointer
          width: 50px
          height: 50px
          padding: 10px
          border-radius: 100px
          background: var(--accent-color)
          &:hover
            border: 1px solid var(--start-color)
            path
              fill: var(--start-color)
        .next
          cursor: pointer
          width: 50px
          height: 50px
          padding: 10px
          border-radius: 100px
          transform: rotate(180deg)
          background: var(--accent-color)
          &:hover
            border: 1px solid var(--start-color)
            path
              fill: var(--start-color)
      .slider
        grid-area: slider
        width: 100%
        .swiper-wrapper
          align-items: flex-start
          justify-content: flex-start
        .slide
          height: 100%
          display: flex
          padding: 30px
          border-radius: 15px
          background: var(--card-color)
          flex-direction: column
          gap: 20px
          .user-info
            display: flex
            flex-direction: column
            gap: 3px
            .name
              font-size: 1.5rem
              font-weight: 600
            .about
              font-size: 1rem
              font-weight: 400
              color: var(--gray-color)
          .text
            max-width: 380px
            font-size: 1.25rem
            font-weight: 400
  .form
    margin: 75px 0
    padding: 60px 0 100px
    display: flex
    align-items: center
    position: relative
    overflow: hidden
    .content
      position: relative
      z-index: 2
      display: flex
      flex-direction: column
      gap: 60px
      .title-text
        text-align: center
        align-items: center
      .h3
        max-width: 540px
      .container
        display: flex
        justify-content: center
        .review-form
          display: flex
          flex-direction: column
          gap: 30px
          background: white
          width: 600px
          padding: 20px 30px
          border-radius: 20px
          box-shadow: 0 1px 16px 0 #0000001A
          .error-message
            color: red
            font-size: 1.25rem
            margin-top: -15px
            padding: 5px 10px
            display: flex
            justify-content: center
          .textarea
            margin-top: -10px
          .input
            width: 100%
            border: 1px solid var(--gray-lines-color)
            padding: 20px
            border-radius: 10px
            font-size: 1.25rem
            color: var(--main-color)
            font-weight: 500
            &::placeholder
              color: var(--gray-color)
              font-weight: 400
              font-size: 1.25rem
          .btn
            border: 1px solid var(--gray-lines-color)
            padding: 20px
            color: white
            border-radius: 100px
            background: var(--gradient)
            font-weight: 600
            font-size: 1.5rem
            transition: 0.2s ease-in-out
            &:hover
              opacity: 0.8
              transition: 0.3s ease-in-out
    .form-image
      opacity: 70%
      object-fit: cover
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
  .conditions
    padding-bottom: 150px
    .content
      border-radius: 15px
      padding: 45px
      display: flex
      flex-direction: column
      gap: 40px
      background: var(--card-color)
      .line
        display: flex
        flex-direction: column
        gap: 20px
        .name
          font-size: 1.5rem
          font-weight: 600
        .text
          font-size: 1.25rem
          font-weight: 400
@media only screen and (max-width: $bp-pc)
  .home-page
    .start
      padding-top: 129px
      margin-bottom: 50px
      .content
        .title
          font-size: 2rem
        .description
          width: 100%
          .text
            font-size: 1.25rem
        .start-container
          flex-direction: column
          .calculator
            width: 100%
            margin-bottom: 60px
          .decor-image1
            display: none
          .decor-image2
            display: block
            align-self: center
    .intro
      .content
        grid-template-areas: 'title title' 'steps steps'
        grid-gap: 40px 0
        .intro-image
          display: none
        .steps
          gap: 20px
          .step_item
            gap: 40px
            .image
              width: 99px
              height: 108px
            .step-info
              gap: 20px
              .name
                font-size: 1.5rem
                font-weight: 600
              .text
                font-size: 1.25rem
                font-weight: 400
    .garant
      .content
        .garant-blocks
          flex-direction: column
      .content
        .garant-blocks
          flex-direction: column
          justify-content: unset
          .block
            display: flex
            flex-direction: row
            align-items: center
            gap: 30px
            border-radius: 15px
            border: 2px solid #2C9FFA
            background: #2C9FFA
            .image-cont
              .image
                display: none
                border-top-left-radius: 12px
                border-bottom-left-radius: 12px
              .image-ext
                display: block
                border-top-left-radius: 12px
                border-bottom-left-radius: 12px
            .text
              max-width: unset
              padding-right: 30px
              color: #FFF
              text-align: center
              font-size: 1.25rem
              font-weight: 600
    .catalog-section
      .content
        .btn
          padding: 20px 80px
          font-size: 1.25rem
    .reviews
      max-width: 100%
      .content
        display: grid
        grid-gap: 30px 0
        grid-template-areas: 'title nav' 'slider slider'
        .title-text
          grid-area: title
        .nav-btns
          grid-area: nav
          display: flex
          flex-direction: row
          gap: 10px
          justify-content: flex-end
          align-items: flex-end
          .prev
            cursor: pointer
            width: 40px
            height: 40px
            padding: 10px
            border-radius: 100px
            background: var(--accent-color)
          .next
            cursor: pointer
            width: 40px
            height: 40px
            padding: 10px
            border-radius: 100px
            transform: rotate(180deg)
            background: var(--accent-color)
        .slider
          max-width: 100%
          overflow: hidden
          min-width: calc(100% + 68px)
          margin-left: -34px
          padding-left: 34px
          padding-right: 34px
          .swiper-wrapper
            align-items: flex-start
            justify-content: flex-start
          .slide
            max-width: 100%
            height: 100%
            .user-info
              .about
                font-size: 0.875rem
              .name
                font-size: 1.25rem
                font-weight: 600
            .text
              max-width: 280px
              font-size: 1rem
    .form
      margin: 50px 0
      padding: 60px 0 80px
      .content
        position: relative
        z-index: 2
        display: flex
        flex-direction: column
        gap: 60px
        .h3
          max-width: 540px
        .container
          .review-form
            display: flex
            flex-direction: column
            gap: 30px
            background: white
            width: 532px
            padding: 20px 30px
            .error-message
              font-size: 1rem
              margin-top: -20px
              padding: 0 10px
            .input
              padding: 20px
              font-size: 1rem
              &::placeholder
                font-size: 1rem
            .btn
              font-size: 1.25rem
    .conditions
      padding: 50px 46px 100px
      .content
        padding: 30px
        background: var(--card-color)
        .line
          gap: 10px
          .name
            font-size: 1.25rem
          .text
            font-size: 1rem
@media only screen and (max-width: $bp-tablet)
  .home-page
    .start
      margin-bottom: 40px
      .content
        .title
          font-size: 1rem
          margin-bottom: 20px
        .description
          gap: 15px
          padding: 15px
          margin-bottom: 40px
          .text
            font-size: 0.875rem
        .start-container
          flex-direction: column
          .calculator
            width: unset
            margin-bottom: 40px
          .decor-image1
            display: none
          .decor-image2
            display: block
            align-self: center
    .intro
      .content
        grid-gap: 30px 0
        .steps
          gap: 10px
          .step_item
            gap: 20px
            padding: 20px 10px
            .image
              width: 55px
              height: 60px
            .step-info
              gap: 10px
              .name
                font-size: 1rem
                font-weight: 600
              .text
                font-size: 0.875rem
                font-weight: 400
    .garant
      .content
        .garant-blocks
          flex-direction: column
      .content
        .garant-blocks
          flex-direction: column
          justify-content: unset
          gap: 10px
          .block
            display: flex
            flex-direction: row
            align-items: center
            gap: 10px
            border-radius: 15px
            border: 2px solid #2C9FFA
            background: #2C9FFA
            .image-cont
              width: 46%
              .image
                display: none
                border-top-left-radius: 12px
                border-bottom-left-radius: 12px
              .image-ext
                display: block
                border-top-left-radius: 12px
                border-bottom-left-radius: 12px
            .text
              max-width: unset
              padding-right: 10px
              color: #FFF
              text-align: center
              font-size: 1rem
              font-weight: 600
    .catalog-section
      .content
        gap: 30px
        .btn
          font-size: 1rem
          padding: 10px 60px
    .reviews
      .content
        display: grid
        grid-gap: 30px 0
        grid-template-areas: 'title nav' 'slider slider'
        .title-text
          grid-area: title
        .nav-btns
          grid-area: nav
          display: flex
          flex-direction: row
          gap: 10px
          justify-content: flex-end
          align-items: flex-end
          .prev
            cursor: pointer
            width: 40px
            height: 40px
            padding: 10px
            border-radius: 100px
            background: var(--accent-color)
          .next
            cursor: pointer
            width: 40px
            height: 40px
            padding: 10px
            border-radius: 100px
            transform: rotate(180deg)
            background: var(--accent-color)
        .slider
          .swiper-wrapper
            align-items: flex-start
            justify-content: flex-start
          .slide
            height: 100%
            padding: 20px
            .user-info
              .about
                font-size: 0.875rem
              .name
                font-size: 1.25rem
                font-weight: 600
            .text
              max-width: 280px
              font-size: 1rem
    .form
      margin: 50px 0
      background: #E8F3FF
      padding: 50px 0
      .content
        position: relative
        z-index: 2
        display: flex
        flex-direction: column
        gap: 40px
        .h3
          max-width: 540px
          font-size: 1.375rem
        .container
          .review-form
            display: flex
            flex-direction: column
            gap: 20px
            background: white
            width: 100%
            padding: 20px
            .error-message
              font-size: 0.75rem
              margin-top: -15px
              padding: 0
            .input
              padding: 10px 15px
              font-size: 0.875rem
              &::placeholder
                font-size: 0.875rem
            .btn
              font-size: 1rem
              padding: 10px
      .form-image
        display: none

    .conditions
      padding: 40px 0 80px
      .content
        padding: 20px
        gap: 20px
        background: var(--card-color)
        .line
          gap: 10px
          .name
            font-size: 1rem
          .text
            font-size: 0.875rem
</style>