<template>
  <Head>
    <title> Займы Онлайн : Лучшие предложения</title>
    <link rel="icon" href="/favicon.ico">
    <link rel="icon" href="/favicon.png">
    <link rel="canonical" href="https://zaim-na-karty-online.ru/zaimi-onlain?sum=" />
    <meta name="description" content="" />
  </Head>
  <div class="container-loader" v-if="loading === true">
    <span class="text">Подбираем подходящие варианты</span>
    <div class="loader">
      <svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" viewBox="0 0 180 180" fill="none">
        <g clip-path="url(#clip0_6_737)">
          <path d="M157.184 30.3047C140.73 11.7422 116.789 2.042e-05 90 1.57361e-05C40.2891 7.04436e-06 -7.04436e-06 40.2891 -1.57361e-05 90L22.5 90C22.5 52.6992 52.6992 22.5 90 22.5C110.566 22.5 128.812 31.8164 141.152 46.336L119.988 67.5L180 67.5L180 7.48831L157.184 30.3047ZM90 157.5C69.4336 157.5 51.1875 148.184 38.8476 133.664L60.0117 112.5L-1.96701e-05 112.5L-3.01629e-05 172.512L22.8164 149.695C39.2695 168.258 63.2461 180 90 180C139.711 180 180 139.711 180 90L157.5 90C157.5 127.301 127.301 157.5 90 157.5Z" fill="#2C9FFA"/>
        </g>
        <defs>
          <clipPath id="clip0_6_737">
            <rect width="180" height="180" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
  <div class="products-page" v-else>
    <div class="content">
      <p class="text">
                <span><span class="colorful">"Займы Онлайн"</span> помогут тебе получить заём быстрее и проще. Заполни
                    несколько
                    анкет и получи одобрение.</span>
      </p>
    </div>

    <div class="best-products">
      <div class="content">
        <h1 class="h1">Лучшие предложения для Вас</h1>
        <div class="products" v-if="allZaymi">
          <ProductCard v-for="(product, index) in allZaymi" :product="product" :key="index" />
        </div>
        <div class="nothing" v-else>
          <span class="span">Ничего не найдено...</span>
          <span class="span">Вы можете рассмотреть наши другие предложения.</span>
        </div>
      </div>
    </div>

    <div class="content" v-if="allZaymi.length !== this.zaymi.length">
      <h1 class="h1">Все предложения</h1>
      <div class="products products-list">
        <ProductCard v-for="(product, index) in zaymi" :product="product" :key="index" />
      </div>
    </div>

    <div class="mfo-licence">
      <div class="content">
        <p class="text-center">Информация о компаниях, представленных на сайте:</p>
        <div class="mfo-cards">
          <div class="card-mfo" v-for="lic in licences" :key="lic">
            <span class="name">{{lic.name}}</span>
            <span class="info">{{lic.info}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import zaymi from '@/json/zaymi.json'
import licences from "@/json/licences.json"
import {Head} from "@vueuse/head";
import {ref} from "vue";
export default {
  data() {
    return {
      loading: ref(true),
      zaymi: zaymi,
      licences: licences,
      showModal: false,
      allZaymi: []
    }
  },
  bestProducts: ['a'],
  components: {Head, ProductCard },
  mounted() {
    this.$emit('loading-updated', this.loading);
    if (this.showModal === false) {
      document.documentElement.style.overflowY = 'auto'
    }
    this.getTime()
    this.getBest()
    this.getAll()
  },
  updated() {
    this.$emit('loading-updated', this.loading);
  },
  methods: {
    getTime() {
      document.documentElement.style.overflowY = 'hidden'
      const randomNum = Math.floor(Math.random() * (5 - 2 + 1)) + 2
      setTimeout(() => {
        this.loading = false
        document.documentElement.style.overflowY = 'auto'
      }, randomNum*1000);
    },
    getBest() {
      this.allZaymi = this.zaymi.filter(z => {
        if (z.sum >= this.$route.query.sum) {
          return z
        }
      })
      this.allZaymi = this.allZaymi.sort(this.compareCards)
    },
    getAll() {
      this.zaymi = this.zaymi.sort(this.compareCards)
    },
    compareCards(card1, card2) {
      if (card1.best === 'да' && card2.best !== 'да') {
        return -1;
      } else if (card1.best !== 'да' && card2.best === 'да') {
        return 1;
      }
      if (card1.free_loan === 'да' && card2.free_loan !== 'да') {
        return -1;
      } else if (card1.free_loan !== 'да' && card2.free_loan === 'да') {
        return 1;
      }
      return 0;
    }
  }
}
</script>

<style lang='sass'>
@import '@/assets/styles/base/adaptive'
.products-page
    padding-top: 200px
    padding-bottom: 150px
    .text
        display: flex
        position: relative
        flex-direction: column
        gap: 15px
        font-size: 1.5rem
        padding: 20px 30px
        font-weight: 400
        .bold
            font-weight: 600
        .colorful
            color: var(--accent-color)
        &::before
            content: ''
            position: absolute
            left: 0
            top: 0
            height: 100%
            width: 6px
            background-color: var(--accent-color)
    .best-products
        margin: 60px 0
        padding: 30px 0 60px
        background-color: #E2F8FF
        .content
            .prompt
                font-size: 1.5rem
                font-weight: 400
            .h1
                font-size: 2.5rem
                font-weight: 600
                margin: 20px 0
        .products
            display: grid
            grid-template-columns: repeat(4, 1fr)
            grid-gap: 20px
        .nothing
            display: flex
            flex-direction: column
            gap: 10px
            .span
                font-size: 1.5rem

    .content
        .h1
            font-size: 2.5rem
            font-weight: 600
            margin: 20px 0

    .products-list
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-gap: 20px
        padding-bottom: 150px
        .card
            box-shadow: 0 0 16px 0 #00000040
            .btn
                background: var(--main-color)
    .mfo-licence
        background: #1d239d
        padding-top: 110px
        padding-bottom: 110px
        margin-bottom: -150px
        .top-text
            display: flex
            flex-direction: column
            gap: 20px
            .text-top
                font-size: 1.25rem
                color: white
        .text-center
            margin-bottom: 70px
            font-size: 1.3rem
            text-align: center
            font-weight: 400
            color: white
        .mfo-cards
            display: grid
            grid-template-columns: 1fr 1fr 1fr
            grid-gap: 20px
            .card-mfo
                display: flex
                flex-direction: column
                .name
                    padding: 20px 0
                    border-bottom: 1px solid white
                    color: white
                    font-size: 1.25rem
                .info
                    color: white
                    padding-top: 10px
                    font-size: 0.875rem
@media only screen and (max-width: $bp-pc)
    .products-page
        padding-top: 130px
        padding-bottom: 100px
        .text
            font-size: 1.25rem
            padding: 20px 30px
            gap: 10px
        .best-products
            margin: 30px 0
            padding: 40px 0
            .content
                .prompt
                    font-size: 1.25rem
                .h1
                    font-size: 2.25rem
                    margin: 10px 0
            .products
                grid-template-columns: 1fr 1fr
                margin-top: 20px
        .content
            .h1
                font-size: 2.25rem
                margin: 10px 0
        .products-list
            grid-template-columns: 1fr 1fr
            padding-bottom: 100px
        .mfo-licence
            padding-top: 80px
            padding-bottom: 100px
            margin-bottom: -100px
            .top-text
                .text-top
                    font-size: 1.125rem
            .text-center
                margin-bottom: 40px
                font-size: 1.13rem
            .mfo-cards
                .card-mfo
                    .name
                        font-size: 1.125rem
                    .info
                        word-break: break-word
@media only screen and (max-width: $bp-tablet)
    .products-page
        padding-bottom: 80px
        .text
            font-size: 0.875rem
            padding: 15px 30px
            gap: 10px
        .best-products
            margin: 20px 0
            padding: 30px 0
            .content
                .prompt
                    font-size: 1rem
                .h1
                    font-size: 1.5rem
            .products
                grid-template-columns: 1fr
        .content
            .h1
                font-size: 1.5rem
        .products-list
            grid-template-columns: 1fr
            margin-top: 30px
            padding-bottom: 60px
        .mfo-licence
            padding-top: 50px
            padding-bottom: 50px
            margin-bottom: -80px
            .top-text
                .text-top
                    font-size: 1rem
            .text-center
                margin-bottom: 30px
                font-size: 1.05rem
            .mfo-cards
                grid-template-columns: repeat(2, 1fr)
                grid-gap: 10px
                .card-mfo
                    .name
                        font-size: 1rem
                        padding: 15px 0
                    .info
                        font-size: 0.75rem
</style>