<template>
  <div class="error-page">
    <div class="content">
      <p class="title">Ошибка 404</p>
      <p class="description">Кажется, такой страницы не существует</p>
      <router-link to="/" class="back-to-main">Вернуться на главную страницу</router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="sass">
@import '@/assets/styles/base/adaptive'
.error-page
  .content
    width: 100svw
    height: calc(100svh - 108px)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    .title
      color: var(--text-color)
      font-variant-numeric: lining-nums proportional-nums
      font-size: 4rem
      font-weight: 700
      margin-bottom: 20px
    .description
      color: #222
      font-variant-numeric: lining-nums proportional-nums
      font-size: 1.25rem
      font-weight: 700
      margin-bottom: 60px
    .back-to-main
      border-radius: 100px
      border: 2px solid var(--text-color)
      padding: 20px 30px
      justify-content: center
      align-items: center
      background: transparent
      color: var(--text-color)
      font-size: 1.25rem
      font-weight: 700
@media only screen and (max-width: $bp-pc)
  .error-page
    .content
      height: calc(100svh - 78px)
@media only screen and (max-width: $bp-tablet)
  .error-page
    .content
      height: calc(100svh - 61px)
      .title
        font-size: 2.25rem
        margin-bottom: 10px
      .description
        font-size: 1rem
        margin-bottom: 30px
        text-align: center
      .back-to-main
        padding: 15px 30px
        font-size: 0.875rem
</style>