import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueYandexMetrika from 'vue3-yandex-metrika'
import "@/assets/styles/index.sass"
import {createHead} from "@vueuse/head";

const settings = {
    id: 96238330,
    router: router,
    webvisor: true,
    clickmap: true,
    env: "production"
}
const head = createHead()
const app = createApp(App)

app.use(router).use(head).use(VueYandexMetrika, settings).mount('#app')
