<template>
  <div class="wrapper">
    <div class="modal">
      <button @click="closeModal" class="close-btn">
        <img src="@/assets/images/icons/close-btn.svg" alt="">
      </button>
      <div class="text">
        <span>
          Я ваш персональный помощник и помогу подобрать наиболее выгодное предложение.
          Заполните форму, чтобы посмотреть лучшие варианты.
        </span>
      </div>
      <div class="images">
        <img class="robot" src="@/assets/images/robot.webp" alt="">
        <img class="line" src="@/assets/images/line.webp" alt="">
      </div>
      <v-calculator :isModal="isModal"/>
    </div>
  </div>
</template>

<script>
import VCalculator from "@/components/UI/vCalculator.vue";
export default {
  components: {
    VCalculator
  },
  data() {
    return {
      isModal: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/styles/base/adaptive"
.wrapper
  z-index: 100000
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  display: flex
  justify-content: center
  align-items: center
  background: rgba(0, 0, 0, 0.2)
  .modal
    position: relative
    background: var(--start-color)
    border-radius: 20px
    padding: 30px 40px
    display: grid
    width: 1100px
    grid-template-areas: "text text" "images calc"
    grid-template-columns: auto 1fr
    grid-gap: 30px 60px
    .close-btn
      position: absolute
      top: 15px
      right: 20px
      border: none
      background: transparent
      padding: unset
    .text
      grid-area: text
      background: var(--accent-color)
      padding: 20px
      border-radius: 20px
      span
        color: #FFF
        font-size: 1.5rem
        font-weight: 500
    .images
      grid-area: images
      position: relative
      margin-bottom: -30px
      display: flex
      align-items: center
      .robot
        height: 70%
      .line
        position: absolute
        top: 0
        right: 0
    .calculator
      grid-area: calc
@media only screen and (max-width: $bp-pc)
  .wrapper
    z-index: 100000
    position: fixed
    top: 0
    left: 0
    height: 100vh
    width: 100vw
    display: flex
    justify-content: center
    align-items: center
    background: rgba(0, 0, 0, 0.2)
    .modal
      position: relative
      background: var(--start-color)
      border-radius: 20px
      padding: 30px 40px
      margin: 0 20px
      display: grid
      width: 1100px
      grid-template-areas: "text text" "images calc"
      grid-template-columns: auto 1fr
      grid-gap: 30px 40px
      .close-btn
        position: absolute
        top: 15px
        right: 20px
        border: none
        background: transparent
        padding: unset
      .text
        span
          font-size: 1.25rem
      .images
        grid-area: images
        position: relative
        margin-bottom: -30px
        display: flex
        align-items: end
        .robot
          height: 60%
        .line
          position: absolute
          height: 40%
          width: 30%
          top: 0
          right: 0
      .calculator
        grid-area: calc
        padding: 30px
        width: 100%
        gap: 20px
        .range
          .input-block
            .input
              font-size: 1.25rem
            .btns
              .btn
                width: 46px
                padding: 0
                font-size: 2rem
          .range-cost
            span
              font-size: 1rem
        .question-block
          .checkbox
            .check
              margin-top: unset
            .label
              margin-top: 1px
              font-size: 1rem
        .btn
          padding: 10px
          font-size: 1.125rem
@media only screen and (max-width: $bp-tablet)
  .wrapper
    z-index: 100000
    position: fixed
    top: 0
    left: 0
    height: 100vh
    width: 100vw
    display: flex
    justify-content: center
    align-items: center
    background: rgba(0, 0, 0, 0.2)
    .modal
      position: relative
      background: var(--start-color)
      border-radius: 20px
      padding: 30px 20px
      display: grid
      margin: 0 20px
      grid-template-areas: "images images" "text text"  "calc calc"
      grid-template-rows: auto auto 1fr
      grid-gap: 20px 60px
      .close-btn
        position: absolute
        top: 15px
        right: 20px
        border: none
        background: transparent
        padding: unset
      .text
        span
          font-size: 0.875rem
      .images
        grid-area: images
        margin-bottom: 0
        .robot
          margin-top: -200px
          height: 120%
        .line
          position: unset
          height: unset
          width: unset
          transform: rotate(280deg)
          top: unset
          bottom: unset
          right: unset
          margin-left: 20px
      .calculator
        grid-area: calc
        padding: 30px
        width: 100%
        gap: 20px
        border-radius: 20px
        margin: 0
        .range
          .input-block
            .input
              font-size: 1.25rem
            .btns
              .btn
                width: 46px
                padding: 0
                font-size: 2rem
          .range-cost
            span
              font-size: 1rem
        .question-block
          .checkbox
            align-items: center
            .check
              width: 13px
              height: 13px
            .label
              font-size: 1rem
        .btn
          padding: 10px
          font-size: 1.125rem
</style>