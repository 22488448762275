import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "@/pages/HomePage.vue";
import ProductsPage from '@/pages/ProductsPage.vue'
import CatalogPage from "@/pages/CatalogPage.vue";
import ErrorPage from "@/pages/ErrorPage.vue";

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/zaimi-onlain',
    component: ProductsPage,
  },
  {
    path: '/catalog',
    component: CatalogPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: () => {
    return window.scrollTo(0, 0);
  },
})

export default router
