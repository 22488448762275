<template>
  <v-header/>
  <router-view @loading-updated="updateLoading"/>
  <v-footer :loading="loading" />
</template>

<style lang="sass">
#app

</style>
<script>
import VHeader from "@/components/vHeader.vue";
import VFooter from "@/components/vFooter.vue";
export default {
  components: {
    VHeader, VFooter
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    console.log(this.$metrika)
  },
  methods: {
    updateLoading(loading) {
      this.loading = loading;
    },
  },
}
</script>