<template>
  <div class="modal-form" @click="closeModal">
    <div class="modal-content">
      <slot></slot>
      <button class="close-btn" @click="closeModal">Закрыть</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit('closeAnsw')
    }
  },
  mounted() {
    document.documentElement.style.overflowY = 'hidden'
  },
}
</script>

<style lang="sass" >
@import "@/assets/styles/base/adaptive"
.modal-form
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, 0.2)
  display: flex
  justify-content: center
  align-items: center
  z-index: 10000000

  .modal-content
    background: white
    border: 2px solid var(--accent-color)
    padding: 30px
    border-radius: 10px
    display: flex
    flex-direction: column
    gap: 20px

    .modal-text
      display: flex
      flex-direction: column
      .text
        color: var(--text-color)
        font-size: 1.125rem
        font-style: normal
        font-weight: 700
        line-height: normal

    .close-btn
      border-radius: 100px
      padding: 10px 20px
      border: 2px solid var(--text-color)
      color: var(--text-color)
      background: transparent
      font-size: 1.25rem
      font-style: normal
      font-weight: 700
      line-height: normal
      cursor: pointer

      &:hover
        border: 2px solid white
        background: var(--gradient)
        color: white
        transition: 0.2s ease-in-out
@media only screen and (max-width: $bp-pc)
  .modal-form
    .modal-content
      margin: 0 50px
      padding: 30px
      gap: 20px
      .modal-text
        .text
          text-align: center
          font-size: 1rem
      .close-btn
        padding: 10px 20px
        font-size: 1.125rem
@media only screen and (max-width: $bp-tablet)
  .modal-form
    .modal-content
      margin: 0 20px
      padding: 30px
      gap: 20px
      .modal-text
        .text
          font-size: 0.8125rem
      .close-btn
        padding: 5px
        font-size: 0.875rem
</style>
