<template>
    <div class="card">
        <div class="top">
          <img :src="product.logo" alt="" class="logo">
          <div class="marks">
            <div class="mark free" v-if="product.free_loan === 'да'"><span>Первый заем бесплатно</span></div>
            <div class="mark best" v-if="product.best === 'да'"><span>Самый выгодный</span></div>
          </div>
        </div>
        <p class="info"><span class="key">Сумма займа</span><span class="value">до {{ formattedNumber }}₽</span></p>
        <p class="info"><span class="key">% ставка</span><span class="value">{{ product.percent }}%</span></p>
        <p class="info"><span class="key">Срок займа</span><span class="value">до {{ product.duration }} дней</span></p>
        <a :href="product.ref_link" class="btn">Получить деньги</a>
    </div>
</template>

<script>
export default {
    //data() {return{}}
    props: {
        product: {
            type: Object,
            required: true
        }
    },
  computed: {
    formattedNumber() {
      return this.formatNumber(this.product.sum);
    },
  },
  methods: {
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
}
</script>

<style lang='sass'>
@import '@/assets/styles/base/adaptive'
.card
    position: relative
    background-color: #fff
    border-radius: 15px
    .top
        .logo
            height: 70px
            object-fit: contain
            margin: 20px
        .marks
            position: absolute
            top: -5px
            right: -10px
            display: flex
            flex-direction: column
            gap: 10px
            align-items: flex-end
            .mark
                padding: 5px 10px
                border-radius: 100px
                display: flex
                width: fit-content
                span
                    color: #FFF
                    font-size: 0.875rem
                    font-weight: 500
            .best
                background: var(--accent-color)
            .free
                background: var(--text-color)
    .info
        display: flex
        justify-content: space-between
        font-size: 1.125rem
        margin-bottom: 15px
        padding: 0 20px 
        .key
            font-weight: 400
        .value
            font-weight: 600
    .btn
        background: var(--gradient)
        color: #fff
        border-radius: 100px
        padding: 13px
        font-size: 1.125rem
        font-weight: 600
        margin-top: 25px
        width: 100%
        display: block
        text-align: center
@media only screen and (max-width: $bp-tablet)
    .card
        border-radius: 10px
        .logo
            margin: 20px 15px
        .info
            font-size: 1rem
            padding: 0 15px
        .btn
            font-size: 1rem
            margin-top: 0
            

</style>