<template>
  <Head>
    <title>Займы онлайн на карту в МФО - взять микрозайм без отказа и проверок.</title>
    <link rel="icon" href="/favicon.ico">
    <link rel="icon" href="/favicon.png">
    <meta name="description" content="Все займы и микрозаймы от МФО России в 2024 году. Здесь можно подобрать займ во всех МФО, посмотрите процентные ставки, условия выдачи и оформления займов и оставьте заявку на сайте." />
  </Head>
  <div class="container-loader" v-if="loading === true">
    <div class="loader">
      <svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" viewBox="0 0 180 180" fill="none">
        <g clip-path="url(#clip0_6_737)">
          <path d="M157.184 30.3047C140.73 11.7422 116.789 2.042e-05 90 1.57361e-05C40.2891 7.04436e-06 -7.04436e-06 40.2891 -1.57361e-05 90L22.5 90C22.5 52.6992 52.6992 22.5 90 22.5C110.566 22.5 128.812 31.8164 141.152 46.336L119.988 67.5L180 67.5L180 7.48831L157.184 30.3047ZM90 157.5C69.4336 157.5 51.1875 148.184 38.8476 133.664L60.0117 112.5L-1.96701e-05 112.5L-3.01629e-05 172.512L22.8164 149.695C39.2695 168.258 63.2461 180 90 180C139.711 180 180 139.711 180 90L157.5 90C157.5 127.301 127.301 157.5 90 157.5Z" fill="#2C9FFA"/>
        </g>
        <defs>
          <clipPath id="clip0_6_737">
            <rect width="180" height="180" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
  <div class="catalog-page" v-else>
    <h1 class="h1 content">Займы и микрозаймы</h1>
    <div class="catalog-wrapper">
      <div class="catalog content">
        <ProductCard v-for="(product, index) in zaymi" :product="product" :key="index" />
      </div>
    </div>

    <div class="mfo-wrapper">
      <div class="mfo-licence content">
        <p class="text-center">Информация о компаниях, представленных на сайте:</p>
        <div class="mfo-cards">
          <div class="card-mfo" v-for="lic in licences" :key="lic">
            <span class="name">{{lic.name}}</span>
            <span class="info">{{lic.info}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import {Head} from "@vueuse/head";
import zaymi from "@/json/zaymi.json";
import licences from "@/json/licences.json";
import { ref } from 'vue';

export default {
  components: {Head, ProductCard},
  data() {
    return {
      loading: ref(true),
      zaymi: zaymi,
      licences: licences,
    }
  },
  mounted() {
    this.$emit('loading-updated', this.loading);
    this.getTime();
    this.getAll()
  },
  updated() {
    this.$emit('loading-updated', this.loading);
  },
  methods: {
    getTime() {
      document.documentElement.style.overflowY = 'hidden'
      const randomNum = Math.floor(Math.random() * 2) + 1
      setTimeout(() => {
        this.loading = false
        document.documentElement.style.overflowY = 'auto'
      }, randomNum*1000);
    },
    getAll() {
      this.zaymi = this.zaymi.sort(this.compareCards)
    },
    compareCards(card1, card2) {
      if (card1.best === 'да' && card2.best !== 'да') {
        return -1;
      } else if (card1.best !== 'да' && card2.best === 'да') {
        return 1;
      }
      if (card1.free_loan === 'да' && card2.free_loan !== 'да') {
        return -1;
      } else if (card1.free_loan !== 'да' && card2.free_loan === 'да') {
        return 1;
      }
      return 0;
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/styles/base/adaptive"
.catalog-page
  .h1
    margin-top: 108px
    padding-top: 50px
    padding-bottom: 20px
    font-size: 2.5rem
    font-weight: 600
  .catalog-wrapper
    background: #E2F8FF
    .catalog
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-gap: 40px
      padding: 75px 34px
  .mfo-wrapper
    margin-top: 75px
    background: #1d239d
    .mfo-licence
      padding: 75px 34px 150px
      .text-center
        margin-bottom: 70px
        font-size: 1.3rem
        text-align: center
        font-weight: 400
        color: white
      .mfo-cards
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        grid-gap: 20px
        .card-mfo
          display: flex
          flex-direction: column
          .name
            padding: 20px 0
            border-bottom: 1px solid white
            color: white
            font-size: 1.25rem
          .info
            color: white
            padding-top: 10px
            font-size: 0.875rem
@media only screen and (max-width: $bp-pc)
  .catalog-page
    .h1
      margin-top: 78px
      padding-top: 40px
      font-size: 2.25rem
    .catalog-wrapper
      .catalog
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 40px
        padding: 50px 34px
    .mfo-wrapper
      margin-top: 50px
      .mfo-licence
        padding: 50px 34px 100px
        .text-center
          margin-bottom: 40px
          font-size: 1.13rem
        .text-center
          text-align: center
          font-weight: 400
          color: white
        .mfo-cards
          display: grid
          grid-template-columns: 1fr 1fr 1fr
          grid-gap: 20px
          .card-mfo
            .name
              font-size: 1.125rem
            .info
              word-break: break-word
@media only screen and (max-width: $bp-tablet)
  .catalog-page
    .h1
      margin-top: 110px
      padding-top: 30px
      font-size: 1.5rem
    .catalog-wrapper
      .catalog
        display: grid
        grid-template-columns: 1fr
        grid-gap: 20px
        padding: 30px 20px
    .mfo-wrapper
      .mfo-licence
        padding: 30px 20px 60px
        .text-center
          margin-bottom: 40px
          font-size: 1.13rem
        .mfo-cards
          display: grid
          grid-template-columns: 1fr 1fr
          grid-gap: 10px
          .card-mfo
            .name
              font-size: 1rem
              padding: 15px 0
            .info
              font-size: 0.75rem
</style>